<template>
  <section :class="active || !isToggleVisible ? 'active' : 'inactive'" class="text-container">

    <div class="description-content" v-show="active || !isToggleVisible">
      <div class="description-body">
        <!-- type and date, only for ausgeführte bauten -->
        <div class="projectinfos">
          <p v-if="isProject && type" class="project-type">{{ type }}</p>
          <p v-if="date" class="project-date">Ausführung: {{ date }}</p>
          <p v-if="prizes && !isCompetitionStudyMobile && !isCompetitionStudyDesktop" class="project-prize-not-competition" v-html="prizes"></p>
        </div>

        <!-- Type & Prizes, only on Mobile-->
        <div v-if="isCompetitionStudyMobile" class="type-and-prizes-container-above">
          <p v-if="type" class="project-type">{{ type }}</p>
          <div v-if="prizes" class="project-prizes-below" v-html="prizes"></div>
        </div>

        <div v-html="description"></div>
      </div>
    </div>

    <div v-if="isCompetitionStudyDesktop"  v-show="active || !isToggleVisible" class="type-and-prizes-container-below">
      <p v-if="type" class="project-type-below">{{ type }}</p>
      <div v-if="prizes" class="project-prizes-below" v-html="prizes"></div>
    </div>

    <p
      v-if="isToggleVisible"
      class="description-toggle pointer"
      :style="active ? `background: ${bgValue};`: ''"
      @click="toggleDescription">{{ active ? '−' : '+' }} Infos</p>

    </div>
  </section>
</template>

<script>
import store, { isMobile } from "../../store"

export default {
  name: "ProjectDescription",
  props: {
    isProject: {
      type: Boolean,
      required: false
    },
    isCompetition: {
      type: Boolean,
      required: false
    },
    isProjectstudy: {
      type: Boolean,
      required: false
    },
    prizes: {
      type: String,
      required: false
    },
    date: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: true
    },
    bgValue: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    isMobile() {
      return isMobile()
    },
    isToggleVisible() {
      // it’s rather complicated: toggle is visible when
      //  - if it’s a projectinactve and project
      //  - if its competition/projectstudy and mobile
      if ( this.isProjectstudy || this.isCompetition ) {
        if ( isMobile() ) {
          return true
        }
      }
      if ( this.isProject ) {
        return true
      }
    },
    isCompetitionStudyDesktop() { // I apologize, this is stupid
      if ( !isMobile() ) {
        if ( this.isCompetition ) { //this.isProjectstudy || removed 11 feb 2021 because should only be for competitions not for laufende projekte?
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isCompetitionStudyMobile() { // I apologize, this is stupid
      if ( isMobile() ) {
        if ( this.isCompetition ) { //this.isProjectstudy || removed 11 feb 2021 because should only be for competitions not for laufende projekte?
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    toggleDescription() {
      let payload
      if (this.active) {
        this.active = false
        // emit event to tell parent to
        // switch off background
        payload = "off"
      } else {
        this.active = true
        payload = "on"
      }
      // emit event with
      // payload "off" or "on"
      this.$emit('description-toggled', payload);
    }
  }
}
</script>
