<template>
  <article class="project">
    <h1 class="project-title upper">{{ project.title }}, {{ project.location }}</h1>

    <!-- section.project-images -->
    <!-- gets events from component when image switched-->
    <ProjectImages
      @image-switched="handleImageEvent"
      v-show="!descriptionActive"
      :images="imagePlanCombo"
      :num-plans="project.plans ? project.plans.length : 0"
      class="project-images project-images-plans-combo project-left-col" />

    <!-- section.project-description -->
    <!-- gets events from component when description toggled-->
    <ProjectDescription
      @description-toggled="handleDescriptionEvent"
      :is-projectstudy="true"
      :description="project.description"
      :prizes="project.prizes"
      :type="project.type"
      :bg-value="project.bgColor"
      class="project-description project-right-col" />

    <!-- section.optional-background -->
    <section
      class="optional-background"
      :class="(firstImgVisible || descriptionActive) ? 'filled' : 'transparent'"
      :style="`background: ${project.bgColor}`"></section>

  </article>
</template>

<script>
import ProjectImages from "./ProjectImages.vue"
import ProjectDescription from "./ProjectDescription.vue"
import store, { isMobile, isTitleVisible, hideTitle } from "../../store"

export default {
  name: "Projectstudy",
  components: {
    ProjectImages,
    ProjectDescription
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      descriptionActive: false,
      firstImgVisible: true
    }
  },
  computed: {
    isMobile() {
      return isMobile()
    },
    imagePlanCombo() {
      if(this.project.plans) {
        return [...this.project.images, ...this.project.plans]
      } else {
        return [...this.project.images]
      }
    },
  },
  methods: {
    handleDescriptionEvent(payload) {
      if (payload === "off") { // description closed
        this.descriptionActive = false
      } else {
        this.descriptionActive = true

        // the bad stuff:
        // when the description of the first project is toggled
        // after the first page load, the page tile needs to go away
        if (isMobile() && isTitleVisible()) {
          hideTitle()
        }
      }
    },
    handleImageEvent(payload) {
      if (payload === 0) { // first image visible
        this.firstImgVisible = true
      } else {
        this.firstImgVisible = false
      }
    }
  }
}
</script>
