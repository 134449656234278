<template>
  <nav :class="navActive ? 'active' : 'inactive'">

    <section class="nav-ui" @click="navActive = !navActive">
      <Hamburger />
      <Close />
    </section>

    <section class="nav-content">
      <h1 class="nav-heading upper">Projekte</h1>

      <ul class="unstyled-list">
        <li v-for="projectType, key in projectlist"><a class="un-deco" :href="`/${projectType.slug}`">{{ projectType.name }}</a></li>
        <li><a class="un-deco pointer" @click="subnavActive = true">Werkliste</a></li>
      </ul>

      <h1 class="nav-heading upper">Büro</h1>

      <ul class="unstyled-list">
        <li v-show="isMobile"><a class="un-deco" href="/buero/#team" @click="closeNav">Team</a></li>
        <li v-show="isMobile"><a class="un-deco" href="/buero/#unternehmen" @click="closeNav">Unternehmen</a></li>
        <li v-show="!isMobile"><a class="un-deco" href="/buero/#unternehmen" @click="closeNav">Team<br>
        Unternehmen</a></li>
        <li><a class="un-deco" href="/buero/#kontakt" @click="closeNav">Kontakt</a></li>
      </ul>
    </section>

    <ProjectLinkList :project-list="projectlist" v-show="subnavActive" @close-nav="closeNav" @close-subnav="closeSubnav"/>

  </nav>
</template>

<script>
import Hamburger from "./ui/Hamburger.vue"
import Close from "./ui/Close.vue"
import ProjectLinkList from "./navigation/ProjectLinkList.vue"
import store, {isMobile} from "../store"

export default {
  name: "Navigation",
  props: {
    projectlist: {
      type: Array,
      required: true
    }
  },
  components: {
    Hamburger,
    Close,
    ProjectLinkList
  },
  data() {
    return {
      navActive: false,
      subnavActive: false
    }
  },
  computed: {
    isMobile() {
      return isMobile()
    }
  },
  methods: {
    closeNav() {
      this.navActive = false
      this.subnavActive = false
    },
    closeSubnav() {
      this.subnavActive = false
    }
  }
}
</script>
